@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

*,
::after,
::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
ul {
  list-style: none;
}

a {
  text-decoration: none;
}

body {
  background-color:  #f1f1f1;
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #c9c8c8;
  border-radius: 1rem;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #b3b2b2;
}